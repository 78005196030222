<template>
  <main>
    <CCard>
      <CAlert
        id="ChooseNotice"
        v-if="(ChooseIndex.length > 0)"
        color="info"
        :fade="false"
      >
          <span class="text">
            {{ $t('Global.SelectedLabel') }}
            <span class="font-weight-bold">{{ ChooseIndex.length }}</span>
            {{ $t('Global.ItemUnit') }}
          </span>
        <div class="ButtonGroup">
          <CButton color="light" size="sm" class="mr-2" @click="ChooseIndex = [];CheckAllValue = false">
            {{ $t('Global.Cancel') }}
          </CButton>
          <CButton v-if="$store.state.user.permission.Permission.Products.Items.includes('W')" color="info" size="sm"
                   class="mr-2" @click="BulkEditModel = true">{{ $t('Global.BulkEdit') }}
          </CButton>
          <CButton v-if="$store.state.user.permission.Permission.Products.Items.includes('W')" color="info" size="sm"
                   class="mr-2" @click="BulkEditSKUModal = true">{{ $t('Product/List.BulkEditSKU') }}
          </CButton>
          <CButton v-if="$store.state.user.permission.Permission.Products.Items.includes('D')" color="danger" size="sm"
                   @click="AlertModel = true">{{ $t('Global.Delete') }}
          </CButton>
        </div>
      </CAlert>
      <CCardHeader>
        <CRow class="d-flex align-items-center">
          <CCol col="6">
            <h5 class="mb-0">{{ $t('Navigation.Products/List') }}</h5>
          </CCol>
          <CCol col="6" class="d-flex justify-content-end">
            <CDropdown addTogglerClasses="btn-outline-success btn-pill" placement="bottom-start" class="d-inline-block mr-2">
              <template #toggler-content>
                <CIcon size="sm" name="cil-library" class="mr-1" />{{ $store.getters.collection && $store.getters.collection.label || '預設資料集' }}
              </template>
              <CDropdownItem v-for="collection in $store.getters.collectionList" :key="collection._id" @click="$store.dispatch('ChangeCollection', collection)">
                {{ collection.label }}
              </CDropdownItem>
            </CDropdown>

            <CDropdown addTogglerClasses="btn-outline-danger btn-pill" placement="bottom-start" class="d-inline-block">
              <template #toggler-content>
                <CIcon size="sm" name="cil-applications-settings" class="mr-1" />{{ $t('Product/List.Action') }}
              </template>
              <CDropdownHeader>
                {{ $t('Global.Export') }}
              </CDropdownHeader>
              <CDropdownItem @click="Export('CSV')">
                {{ $t('Global.ExportToCSV') }}
              </CDropdownItem>
            </CDropdown>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardHeader class="w-100 overflow-auto px-2">
        <CRow class="text-nowrap">
          <CCol col="12">
            <CButton color="white" shape="pill" class="mr-2" @click="FilterModel = true;ShowFilter = 'All'">
              <CIcon name="cil-filter"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('SeriesNum')">
              {{ $t('Product/List.SeriesNum') }}: {{ SearchFilter.SeriesNum.join(',') || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('CustomSeriesNum')">
              {{ $t('Product/List.CustomSeriesNum') }}: {{ SearchFilter.CustomSeriesNum.join(',') || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('SKU')">
              {{ $t('Product/List.SKU') }}: {{ SearchFilter.SKU.join(',') || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Name')">
              {{ $t('Product/List.Name') }}: {{ SearchFilter.Name || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('SelectCategories')">
              {{ $t('Product/List.Categories') }}: {{ SearchFilter.SelectCategoriesName || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('SaleMode')">
              {{ $t('Product/Detail.SaleMode') }}: {{ SearchFilter.SaleModeName || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Status')">
              {{ $t('Product/List.Status') }}: {{ SearchFilter.Status ? $t('Product/List.StatusOptions.' + SearchFilter.Status) : '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Tags')">
              {{ $t('Product/Detail.Tags') }}: {{ SearchFilter.Tags.join(',') || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Sort')">
              {{ $t('Product/Detail.Sort') }}: {{ $t('Product/List.SortType.' + this.Order) || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="danger" shape="pill" @click="ResetFilter()">
              <CIcon size="sm" name="cil-x"/>
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody class="p-0">
        <CDataTable
          id="ProductList"
          :items="List"
          :fields="Field"
          :loading="Loading"
          :noItemsView="noItemsView"
          responsive
          hover
        >
          <template #Checkbox-header>
            <label class="mb-0">
              <input type="checkbox" id="CheckAll" v-model="CheckAllValue" @change="CheckAll()">
            </label>
          </template>
          <template #Checkbox="{item}">
            <td class="text-center">
              <label class="mb-0">
                <input type="checkbox" :id="'Checkbox-List-' + item.SeriesNum" :value="item.SeriesNum"
                       v-model="ChooseIndex">
              </label>
            </td>
          </template>
          <template #Cover="{item}">
            <td>
              <img v-lazy="item.Cover" width="60" height="60" class="img-fluid"/>
            </td>
          </template>
          <template #Info="{item}">
            <td class="small">
              <div>
                <b>{{ $t('Product/List.SeriesNum') }}</b>: {{ item.SeriesNum }}
              </div>
              <div>
                <b>{{ $t('Product/List.CustomSeriesNum') }}</b>: {{ item.CustomSeriesNum || '--' }}
              </div>
              <div class="text-truncate" style="max-width: 300px;">
                <b>{{ $t('Product/List.Name') }}</b>: <a class="text-info" :href="`https://${$store.state.user.permission.Domain}/products/detail/${item.SeriesNum}`" target="_blank">{{ item.Name }}</a>
              </div>
            </td>
          </template>
          <template #Categories="{item}">
            <td>
              <CBadge v-for="(category, index) in item.Categories" :key="index" color="light" class="mr-2">{{category }}</CBadge>
            </td>
          </template>
          <template #Amount="{item}">
            <td class="small text-nowrap">
              <template v-if="item.EnableOption === 1">
                <p class="mb-0"><b>價格區間</b>: <span
                  class="text-danger">{{ $store.state.currency + '$' }}{{ item.Amount.RangeMin }}~{{ item.Amount.RangeMax }}</span></p>
              </template>
              <template v-else>
                <p class="mb-0"><b>{{ $t('Product.ActualAmount') }}</b>: <span
                  class="text-danger">{{ $store.state.currency + '$' }}{{ item.Amount.Actual }}</span></p>
                <p class="mb-0"><b>{{ $t('Product.SuggestAmount') }}</b>: {{
                    $store.state.currency + '$'
                  }}{{ item.Amount.Suggest }}</p>
                <p class="mb-0"><b>{{ $t('Product.CostAmount') }}</b>: {{
                    $store.state.currency + '$'
                  }}{{ item.Amount.Cost }}</p>
              </template>
            </td>
          </template>
          <template #Stock="{item}">
            <td class="small">
              <template v-if="item.EnableStock === 1">
                <p class="mb-0"><b>SKU</b>: {{ item.Stock.SKU }}</p>
                <p class="mb-0"><b>{{ $t('Product.RealStock') }}</b>: {{ item.Stock.RealStock }}</p>
                <p class="mb-0"><b>{{ $t('Product.SafeStock') }}</b>: {{ item.Stock.SafeStock }}</p>
              </template>
              <template v-else>
                <CBadge color="warning">
                  {{ $t('Product/List.UnableStock') }}
                </CBadge>
              </template>
            </td>
          </template>
          <template #Status="{item}">
            <td>
              <CBadge :color="(item.Status === 1 ? 'success' : 'danger')" class="mr-1">
                {{ $t('Product.Status.' + item.Status) }}
              </CBadge>
              <CBadge v-if="$dayjs().unix() < $dayjs(item.OnlineTime).unix()" color="info">
                {{ $t('Product.AvailableStatus.UnStart') }}
              </CBadge>
              <CBadge v-else-if="$dayjs().unix() > $dayjs(item.OfflineTime).unix()" color="danger">
                {{ $t('Product.AvailableStatus.Expired') }}
              </CBadge>
              <CBadge v-else color="success">
                {{ $t('Product.AvailableStatus.Running') }}
              </CBadge>
            </td>
          </template>
          <template #Action="{item}">
            <td>
              <CButton v-if="$store.state.user.permission.Permission.Products.Items.includes('W')" color="info" size="sm" class="mr-1" v-c-tooltip="$t('Product/List.Edit')" @click="$router.push(`/products/detail/${item.SeriesNum}`)">
                <CIcon name="cil-pencil" class="c-icon-custom-size mr-1"/>編輯
              </CButton>
              <CButton class="btn btn-sm btn-secondary mr-1" v-c-tooltip="`查看商品`" @click="OpenWindow(`https://${$store.state.user.permission.Domain}/products/detail/${item.SeriesNum}`)">
                <CIcon name="cil-paper-plane" class="c-icon-custom-size mr-1"/>查看商品
              </CButton>
              <CButton v-if="$store.state.user.permission.Permission.Products.Items.includes('W')" class="btn btn-sm btn-secondary mr-1" v-c-tooltip="$t('Global.BlankTarget')" @click="OpenWindow(`/products/detail/${item.SeriesNum}`)">
                <CIcon name="cil-clone" class="c-icon-custom-size"/>
              </CButton>
              <CButton v-if="$store.state.user.permission.Permission.Products.Items.includes('W')" class="btn btn-sm btn-secondary" v-c-tooltip="$t('Product/List.Duplicate')" @click="Duplicate(item.SeriesNum)">
                <CIcon name="cil-copy" class="c-icon-custom-size"/>
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
      <CCardFooter>
        <CPagination :activePage.sync="Pagination.Page" :pages.sync="Pagination.TotalPages"
                     @update:activePage="ChangePage()"/>
      </CCardFooter>
    </CCard>
    <CModal id="AlertModel" class="ActionModel" :show.sync="AlertModel" :centered="true" :closeOnBackdrop="!Submit">
      <CAlert color="danger">
        <CIcon name="cil-bell"/>
        {{ $t('Global.ConfirmDelete') }}
      </CAlert>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Delete') }}</h5>
          <CButtonClose @click="AlertModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="AlertModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="DeleteSubmit" @click="Delete()" color="danger">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="BulkEditModel" class="ActionModel" :show.sync="BulkEditModel" :centered="true"
            :closeOnBackdrop="!Submit">
      <CRow form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Product/Detail.Status') }}
        </CCol>
        <CCol sm="9">
          <CSwitch color="success" :checked.sync="BulkEditData.Status"/>
        </CCol>
      </CRow>
      <CRow form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Product/Detail.EnableOption') }}
        </CCol>
        <CCol sm="9">
          <CSwitch color="success" :checked.sync="BulkEditData.EnableOption"/>
        </CCol>
      </CRow>
      <CRow form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Product/List.Categories') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.SelectCategories"
            v-model="BulkEditData.Categories"
            :optionHeight="24"
            label="label"
            track-by="value"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="CategoryList"
            :multiple="true"
            :taggable="true"
          >
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CInput
        :label="$t('Product/Detail.Brand')"
        v-model="BulkEditData.Brand"
        horizontal
      />
      <CInput
        :prepend="$store.state.currency + '$'"
        :label="$t('Product/Detail.Amount/Cost')"
        v-model="BulkEditData.Amount.Cost"
        type="number"
        horizontal>
        <template #append-content>
          <CIcon name="cil-dollar"/>
        </template>
      </CInput>
      <CInput
        :prepend="$store.state.currency + '$'"
        :label="$t('Product/Detail.Amount/Suggest')"
        v-model="BulkEditData.Amount.Suggest"
        type="number"
        horizontal>
        <template #append-content>
          <CIcon name="cil-dollar"/>
        </template>
      </CInput>
      <CInput
        :prepend="$store.state.currency + '$'"
        :label="$t('Product/Detail.Amount/Actual')"
        v-model="BulkEditData.Amount.Actual"
        type="number"
        horizontal>
        <template #append-content>
          <CIcon name="cil-dollar"/>
        </template>
      </CInput>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.BulkEdit') }}</h5>
          <CButtonClose @click="BulkEditModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#BulkEditSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="BulkEditModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="BulkEditSubmit" @click="BulkEdit()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="BulkEditSKUModal" class="ActionModel" :show.sync="BulkEditSKUModal" :centered="true"
            :closeOnBackdrop="!Submit">
      <CAlert color="info" :fade="false">
        <i class="fas fa-info-circle mr-2" />將選取的商品導入SKU，並且將商品屬性及成本價格導入至現有商品中。
      </CAlert>
      <CRow form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Product/Detail.Stock/SKU') }} <i v-c-tooltip="{content: $t('Product/Detail.Stock/SKUDescription'),placement: 'bottom'}" class="fas fa-question-circle text-info ml-1" />
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SelectSKU"
            v-model="CurrentSKU"
            label="Name_SKU"
            track-by="SKU"
            :tag-placeholder="$t('Product/Stock.EnterToAdd')"
            :placeholder="$t('Product/Stock.EnterToAdd')"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="SKUList"
            :showNoResults="false"
            :showNoOptions="false"
          >
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Product/List.BulkEditSKU') }}</h5>
          <CButtonClose @click="BulkEditSKUModal = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#BulkEditSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="BulkEditSKUModal = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="BulkEditSubmit" :disabled="!CurrentSKU" @click="BulkEditSKU()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="FilterModel" class="ActionModel" :show.sync="FilterModel" :centered="true" :closeOnBackdrop="!Submit">
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'Name'" :label="$t('Product/List.Name')"
              v-model="SearchFilter.Name" horizontal/>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'CustomSeriesNum'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Product/List.CustomSeriesNum') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.CustomSeriesNum"
            v-model="SearchFilter.CustomSeriesNum"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="SearchFilter.CustomSeriesNum"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'SeriesNum'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Product/List.SeriesNum') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.SeriesNum"
            v-model="SearchFilter.SeriesNum"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="SearchFilter.SeriesNum"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'SKU'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Product/List.SKU') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.SKU"
            v-model="SearchFilter.SKU"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="SearchFilter.SKU"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'SelectCategories'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Product/List.Categories') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.SelectCategories"
            v-model="SearchFilter.SelectCategories"
            :optionHeight="24"
            label="label"
            track-by="value"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="CategoryList"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CSelect v-if="ShowFilter === 'All' || ShowFilter === 'Status'" horizontal :label="$t('Product/List.Status')" :options="StatusOptions" v-model="SearchFilter.Status" :value.sync="SearchFilter.Status" :placeholder="$t('Global.PleaseSelect')"/>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'SaleMode'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Product/Detail.SaleMode') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.SaleMode"
            v-model="SearchFilter.SaleMode"
            :optionHeight="24"
            label="label"
            track-by="value"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="SaleModeOptions"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Brand'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Product/Detail.Brand') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Brand"
            v-model="SearchFilter.Brand"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="SearchFilter.Brand"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Tags'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Product/Detail.Tags') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Tags"
            v-model="SearchFilter.Tags"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="Tags"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Sort'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Product/Detail.Sort') }}
        </CCol>
        <CCol sm="9">
          <CInputRadioGroup v-model="Order" :options="SortOption" :checked.sync="Order" :custom="true" inline/>
        </CCol>
      </CRow>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Filter') }}</h5>
          <CButtonClose @click="FilterModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#FilterModelSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="FilterModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="FilterModelSubmit" @click="ChangePage()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <component :is="Component" :Toggle.sync="ToggleModal" @Success="GetList()" />
  </main>
</template>

<route>
{
"meta": {
"label": "商品目錄"
}
}
</route>

<script>
export default {
  name: 'ProductsList',
  layout: 'manage',
  components: {
    Multiselect: () => import('@/plugins/mutiselect'),
  },
  data() {
    return {
      List: [],
      Tags: [],
      CategoryList: [],
      ShowFilter: 'All',
      SearchFilterType: null,
      SearchFilter: {
        SelectCategories: [],
        SelectCategoriesName: '',
        SeriesNum: [],
        CustomSeriesNum: [],
        SKU: [],
        SaleMode: [],
        SaleModeName: '',
        Tags: [],
        Brand: [],
        Name: '',
        Status: ''
      },
      AlertModel: false,
      BulkEditModel: false,
      BulkEditSKUModal: false,
      FilterModel: false,
      BulkEditData: {
        Status: true,
        EnableOption: false,
        Brand: '',
        Amount: {
          Suggest: '',
          Actual: '',
          Cost: ''
        }
      },
      ChooseIndex: [],
      CheckAllValue: false,
      Pagination: {
        Page: 1,
        PerPage: 10,
        TotalItems: 0,
        TotalPages: 0
      },
      CurrentSKU: null,
      SKUList: [],
      Order: 'create_nto',
      Loading: false,
      Submit: false,
      noItemsView: {
        noResults: this.$t('Product/List.NoResults'),
        noItems: this.$t('Product/List.NoItems')
      },
      Component: null,
      ToggleModal: false
    }
  },
  computed: {
    Field() {
      return [
        {key: 'Checkbox', label: ''},
        {key: 'Cover', label: this.$t('Product/List.Cover')},
        {key: 'Info', label: this.$t('Product/List.Info')},
        // {key: 'CustomSeriesNum', label: this.$t('Product/List.CustomSeriesNum')},
        // {key: 'Categories', label: this.$t('Product/List.Categories')},
        {key: 'Amount', label: this.$t('Product/List.Amount')},
        {key: 'Stock', label: this.$t('Product/List.Stock')},
        // {key: 'SeriesNum', label: this.$t('Product/List.SeriesNum')},
        {key: 'Sort', label: this.$t('Product/Detail.Sort')},
        {key: 'Status', label: this.$t('Product/List.Status')},
        {key: 'Action', label: ''}
      ]
    },
    SaleModeOptions() {
      return Object.keys(this.$t('Product/Detail.SaleModeOptions')).map(value => {
        return {
          label: this.$t('Product/Detail.SaleModeOptions.' + value),
          value
        }
      })
    },
    StatusOptions() {
      return Object.keys(this.$t('Product/List.StatusOptions')).map(value => {
        return {
          label: this.$t('Product/List.StatusOptions.' + value),
          value
        }
      })
    },
    SortOption() {
      return [
        {value: 'otn', label: this.$t('Product/List.SortType.otn')},
        {value: 'nto', label: this.$t('Product/List.SortType.nto')},
        {value: 'lth', label: this.$t('Product/List.SortType.lth')},
        {value: 'htl', label: this.$t('Product/List.SortType.htl')},
        {value: 'sort_asc', label: this.$t('Product/List.SortType.sort_asc')},
        {value: 'sort_desc', label: this.$t('Product/List.SortType.sort_desc')},
        {value: 'customSeriesNum_asc', label: this.$t('Product/List.SortType.customSeriesNum_asc')},
        {value: 'customSeriesNum_desc', label: this.$t('Product/List.SortType.customSeriesNum_desc')},
        {value: 'sku_asc', label: this.$t('Product/List.SortType.sku_asc')},
        {value: 'sku_desc', label: this.$t('Product/List.SortType.sku_desc')},
        {value: 'seriesNum_asc', label: this.$t('Product/List.SortType.seriesNum_asc')},
        {value: 'seriesNum_desc', label: this.$t('Product/List.SortType.seriesNum_desc')}
      ]
    }
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.GetList(),
        this.GetCategories(),
        this.GetSKUList()
      ]).then(() => {
        this.Submit = false
        this.List = this.List.map(data => {
          return {
            ...data,
            Categories: this.CategoryList.filter(filterData => data.Categories.includes(filterData.value)).map(mapData => {
              return mapData.label
            })
          }
        })
        Object.keys(this.$route.query).forEach((param) => {
          switch (param) {
            case 'page':
            case 'perPage':
            case 'order':
              break
            case 'category':
              this.SearchFilter.SelectCategories = this.CategoryList.filter(data => this.$route.query.category.split(',').includes(data.value))
              break
            case 'categoryName':
              this.SearchFilter.SelectCategoriesName = this.$route.query.categoryName
              break
            case 'name':
              this.SearchFilter.Name = this.$route.query.name
              break
            case 'status':
              this.SearchFilter.Status = this.$route.query.status
              break
            case 'customSeriesNum':
              this.SearchFilter.CustomSeriesNum = this.$route.query.customSeriesNum.split(',')
              break
            case 'seriesNum':
              this.SearchFilter.SeriesNum = this.$route.query.seriesNum.split(',')
              break
            case 'sku':
              this.SearchFilter.SKU = this.$route.query.sku.split(',')
              break
            case 'saleMode':
              this.SearchFilter.SaleMode = this.SaleModeOptions.filter(data => this.$route.query.saleMode.split(',').includes(data.value))
              break
            case 'saleModeName':
              this.SearchFilter.SaleModeName = this.$route.query.saleModeName
              break
            case 'tags':
              this.SearchFilter.Tags = this.$route.query.tags.split(',')
              break
            case 'brand':
              this.SearchFilter.Brand = this.$route.query.brand.split(',')
              break
          }
        })
        return true
      }).catch((err) => {
        this.Submit = false
        throw err
      })
    },
    GetList() {
      let RequestQuery = {
        page: this.$route.query.page || this.Pagination.Page,
        perPage: this.$route.query.perPage || this.Pagination.PerPage,
        order: this.$route.query.order || this.Order,
        site: this.$store.getters.collection.value
      }
      if (this.$route.query.name) {
        RequestQuery.name = this.$route.query.name
      }
      if (this.$route.query.status) {
        RequestQuery.status = this.$route.query.status
      }
      if (this.$route.query.category) {
        RequestQuery.category = this.$route.query.category
      }
      if (this.$route.query.customSeriesNum) {
        RequestQuery.customSeriesNum = this.$route.query.customSeriesNum
      }
      if (this.$route.query.seriesNum) {
        RequestQuery.seriesNum = this.$route.query.seriesNum
      }
      if (this.$route.query.sku) {
        RequestQuery.sku = this.$route.query.sku
      }
      if (this.$route.query.saleMode) {
        RequestQuery.saleMode = this.$route.query.saleMode
      }
      if (this.$route.query.saleModeName) {
        RequestQuery.saleModeName = this.$route.query.saleModeName
      }
      if (this.$route.query.tags) {
        RequestQuery.tags = this.$route.query.tags
      }
      if (this.$route.query.brand) {
        RequestQuery.brand = this.$route.query.brand
      }
      this.List = []
      this.Loading = true
      this.FilterModel = false
      return this.$store.dispatch('InnerRequest', {
        url: '/product/list',
        method: 'get',
        params: RequestQuery
      }).then(({data}) => {
        this.Loading = false
        this.List = data.list
        this.Tags = data.tags.map(item => item.Name)
        this.Pagination = data.pagination
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    ChangePage() {
      const query = {
        page: this.Pagination.Page,
        perPage: this.Pagination.PerPage,
        order: this.Order,
      }
      //todo 判斷確認 要調整
      if (this.SearchFilter.Name) {
        query.name = this.SearchFilter.Name
      }
      if (this.SearchFilter.Status) {
        query.status = this.SearchFilter.Status
      }
      if (this.SearchFilter.SelectCategories.length > 0) {
        query.category = this.SearchFilter.SelectCategories.map(data => data.value).join(',')
        query.categoryName = this.SearchFilter.SelectCategories.map(data => data.label).join(',')
      }
      if (this.SearchFilter.CustomSeriesNum.length > 0) {
        query.customSeriesNum = this.SearchFilter.CustomSeriesNum.join(',')
      }
      if (this.SearchFilter.SeriesNum.length > 0) {
        query.seriesNum = this.SearchFilter.SeriesNum.join(',')
      }
      if (this.SearchFilter.SKU.length > 0) {
        query.sku = this.SearchFilter.SKU.join(',')
      }
      if (this.SearchFilter.SaleMode.length > 0) {
        query.saleMode = this.SearchFilter.SaleMode.map(data => data.value).join(',')
        query.saleModeName = this.SearchFilter.SaleMode.map(data => data.label).join(',')
      }
      if (this.SearchFilter.Tags.length > 0) {
        query.tags = this.SearchFilter.Tags.join(',')
      }
      if (this.SearchFilter.Brand.length > 0) {
        query.brand = this.SearchFilter.Brand.join(',')
      }
      this.$router.replace({
        path: '/products/list',
        query
      }).then(() => {
        this.Init()
      })
    },
    Delete() {
      this.Submit = true
      return this.$store.dispatch('InnerRequest', {
        url: '/product/delete',
        method: 'post',
        data: {
          seriesNum: this.ChooseIndex
        }
      }).then(() => {
        this.Init()
        this.Submit = false
        this.AlertModel = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Product/DeleteSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Init()
        this.Submit = false
        this.AlertModel = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Product/DeleteFail') + err.msg,
          type: 'error'
        })
      })
    },
    BulkEdit() {
      this.Submit = true
      const updateData = {}
      Object.keys(this.BulkEditData).forEach((index) => {
        //驗證是否為物件不為陣列
        switch (typeof this.BulkEditData[index]) {
          case 'object':
            if (Array.isArray(this.BulkEditData[index]) && this.BulkEditData[index].length > 0) {
              if (index === 'Categories') {
                updateData[index] = this.BulkEditData[index].map(item => item.value)
              } else {
                updateData[index] = this.BulkEditData[index]
              }
            } else {
              Object.keys(this.BulkEditData[index]).forEach((subIndex) => {
                if (this.BulkEditData[index][subIndex] !== '') {
                  updateData[index + '.' + subIndex] = this.BulkEditData[index][subIndex]
                }
              })
            }
            break
          default:
            if (this.BulkEditData[index] !== '') {
              updateData[index] = this.BulkEditData[index]
            }
            break
        }
      })
      return this.$store.dispatch('InnerRequest', {
        url: '/product/bulkEdit',
        method: 'post',
        data: {
          seriesNum: this.ChooseIndex,
          updateData
        }
      }).then(() => {
        this.Init()
        this.BulkEditModel = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Product/BulkEditSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Init()
        this.BulkEditModel = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + err.msg,
          type: 'error'
        })
      })
    },
    BulkEditSKU() {
      if (!this.CurrentSKU) {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: '請設定SKU後再更新',
          type: 'success'
        })
        return true
      }
      this.Submit = true
      const updateData = {
        Model: this.CurrentSKU.Model,
        EnableStock: 1,
        'Stock.SKU': this.CurrentSKU.SKU,
        'Amount.Cost': this.CurrentSKU.Amount.Cost,
        'Variable.Weight': this.CurrentSKU.Variable.Weight,
        'Variable.NetWeight': this.CurrentSKU.Variable.NetWeight,
        'Variable.Length': this.CurrentSKU.Variable.Length,
        'Variable.Width': this.CurrentSKU.Variable.Width,
        'Variable.Height': this.CurrentSKU.Variable.Height,
        'Variable.Temperature': this.CurrentSKU.Variable.Temperature
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/product/bulkEdit',
        method: 'post',
        data: {
          seriesNum: this.ChooseIndex,
          updateData
        }
      }).then(() => {
        this.Init()
        this.BulkEditSKUModal = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Product/BulkEditSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Init()
        this.BulkEditSKUModal = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + err.msg,
          type: 'error'
        })
      })
    },
    GetSKUList() {
      return this.$store.dispatch('InnerRequest', {
        endPoint: '/service',
        url: '/product/availableList',
        method: 'post',
        data: {
          Project: this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project,
          Taxonomy: 'Stock'
        }
      }).then(({ data }) => {
        this.SKUList = data.Data.map(item => {
          return {
            label: `${item.Name} (SKU: ${item.CustomSeriesNum})`,
            value: item.CustomSeriesNum,
            SKU: item.CustomSeriesNum,
            Name_SKU: `${item.Name} (SKU: ${item.CustomSeriesNum})`,
            Amount: item.Amount,
            Variable: item.Variable,
            Model: item.Model
          }
        })
      })
    },
    CheckAll() {
      if (this.CheckAllValue === true) {
        this.ChooseIndex = this.List.map(data => {
          return data.SeriesNum
        })
      } else {
        this.CheckAllValue = false
        this.ChooseIndex = []
      }
    },
    AddSearchFilter(newTag, id) {
      id = id.replace('SearchFilter.', '')
      this.SearchFilter[id].push(newTag.trim())
    },
    OpenFilter(type = 'All') {
      this.FilterModel = true
      this.ShowFilter = type
    },
    ResetFilter() {
      this.Submit = false
      this.AlertModel = false
      this.BulkEditModel = false
      this.BulkEditData = {
        Status: true,
        Brand: '',
        Amount: {
          Suggest: '',
          Actual: '',
          Cost: ''
        }
      }
      this.SearchFilter = {
        SelectCategories: [],
        SelectCategoriesName: '',
        SeriesNum: [],
        CustomSeriesNum: [],
        SKU: [],
        SaleMode: [],
        Tags: [],
        Brand: [],
        Name: '',
        Status: ''
      }
      this.ChooseIndex = []
      this.$router.replace({
        path: '/products/list'
      }).then(() => {
        this.Init()
      })
    },
    OpenWindow(_link) {
      return window.open(_link, '_blank');
    },
    GetCategories () {
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/category/availableList',
          Data: {}
        }
      }).then(({data}) => {
        this.CategoryList = data.Data.map((category) => {
          return { label: category.Name, value: category.Slug }
        })
      }).catch((err) => {
        throw err
      })
    },
    Duplicate(SeriesNum) {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/product/duplicate',
        method: 'get',
        params: {
          seriesNum: SeriesNum
        }
      }).then(() => {
        this.GetList()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Product/SuccessCopy'),
          type: 'success'
        })
      }).catch((error) => {
        console.log(error)
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Product/FailCopy') + error.msg,
          type: 'error'
        })
      })
    },
    Export(type = 'GoogleSheets') {
      switch (type) {
        case 'GoogleSheets':
          this.Component = () => import('@/components/ExportToGoogleSheet')
          setTimeout(() => {
            this.ToggleModal = true
          }, 500)
          break
        default:
          this.Loading = true
          this.$Progress.start()
          return this.$store.dispatch('InnerRequest', {
            endPoint: '/import',
            url: '/product/export/csv',
            method: 'post',
            data: {}
          }).then(({ data }) => {
            this.Loading = false
            this.$Progress.finish()
            const BinaryString = window.atob(data)
            const Bytes = new Uint8Array(BinaryString.length)
            for (let index = 0; index < BinaryString.length; index++) {
              Bytes[index] = BinaryString.charCodeAt(index)
            }
            const Data = new Blob([Bytes], {type: 'application/octet-stream'})
            const blobURL = URL.createObjectURL(Data)
            const tempLink = document.createElement('a')
            tempLink.style.display = 'none'
            tempLink.href = blobURL
            tempLink.setAttribute('download', `商品列表-${this.$dayjs().format('YYYYMMDD')}.csv`, '_blank')
            document.body.appendChild(tempLink)
            tempLink.click()
            document.body.removeChild(tempLink)
            window.URL.revokeObjectURL(blobURL)
          }).catch((err) => {
            this.Submit = false
            this.$Progress.fail()
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Error'),
              text: this.$t('Message.Global/ErrorMessage') + err.msg,
              type: 'error'
            })
            throw err
          })
      }
    }
  }

}
</script>

<style>
#ChooseNotice {
  position: absolute;
  z-index: 10;
  width: 100%;
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  padding: 0.5rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#ProductList thead th:first-child {
  text-align: center;
}

#ProductList tbody td:first-child {
  vertical-align: middle;
}
</style>
